<template>
    <div class="c-edmund-holder">
        <div class="c-edmund">
            <div class="c-edmund-eye c-edmund-eye--left">
                <div class="c-edmund-eyelid"></div>
                <div class="c-edmund-iris c-edmund-iris--left"></div>
            </div>
            <div class="c-edmund-eye c-edmund-eye--right">
                <div class="c-edmund-eyelid"></div>
                <div class="c-edmund-iris c-edmund-iris--right"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.addEventListener('mousemove', this.enableAnimation) 
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.enableAnimation)
    },
    methods: {
        enableAnimation(mousePosition) {
            const leftEye = document.querySelector('.c-edmund-iris--left')
            const rightEye = document.querySelector('.c-edmund-iris--right') 
            
            const xPosition = -(window.innerWidth / 2 - mousePosition.pageX) / 200
            const yPosition = -(window.innerHeight / 2 - mousePosition.pageY) / 80

            leftEye.style.transform = `translateY(${yPosition}px) translateX(${xPosition}px)`
            rightEye.style.transform = `translateY(${yPosition}px) translateX(${xPosition}px)`
        }
    }
}
</script>
 