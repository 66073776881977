<template> 
    <div class="container">
        <div class="row text-center mb-5">
            <div class="col-12"> 
                <h2 class="u-color-green">{{ displayErrorTitle }}</h2>
                
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <error-page />
                <h5 class="mt-3">{{PROVIDER.ErrorPage.ErrorMessages.ErrorCode}}: {{ responseCode }}</h5>
            </div>
        </div>
    </div>
</template>
 

<script> 
import { PROVIDER } from '@/constants/EdumsProviderConstants.json' 
import ErrorPage from "@/../../shared/components/Shared/ErrorPage.vue"

export default {
    name: 'NotFound',  
    props: {
        responseCode: {
            type: Number,
            default: 404
        },
    },
    components: {
        ErrorPage
    },
    computed: {
        displayErrorTitle() {
            let errorMessage

            switch (this.responseCode) {
                case 400: errorMessage = PROVIDER.ErrorPage.ErrorMessages.BadRequest ; break;
                case 401: errorMessage = PROVIDER.ErrorPage.ErrorMessages.NoAccess ; break;
                case 403: errorMessage = PROVIDER.ErrorPage.ErrorMessages.NoAccess ; break;
                case 404: errorMessage = PROVIDER.ErrorPage.ErrorMessages.NotFound ; break;
                case 500: errorMessage = PROVIDER.ErrorPage.ErrorMessages.Network ; break;
            }

            return errorMessage
        }
    },
    data() {
        return {
            PROVIDER
        }
    },
    
}
</script>